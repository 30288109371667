import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars, faBell,faSignOut,faHome,faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
function Header() {
  return (
    <>
      <header className="section-t-space theme-btn ">
        <div className="custom-container">
          <div className="header-panel">
            <Link to="/home" className="back-btn">
              <FontAwesomeIcon icon={faHome} className="icon" />
            </Link>
           <img src="logo.jpg" alt="logo" className="logo" />

           <Link
                to="/home"
                className="back-btn"
                role="button"
                data-bs-toggle="dropdown"
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  className="icon"
                ></FontAwesomeIcon>
              </Link>

            {/* <div className="dropdown">
            
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    Most recent{" "}
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#period"
                    data-bs-toggle="modal"
                  >
                    Custom
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Last 1 month
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Remove all
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
