import React, { useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ApiService from "../Api/ApiService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Signuppage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    //const tid = toast.loading("Please wait...", { isLoading: true });

   var response= await ApiService.registercallapi(data);
   console.log(response);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="auth-form">
        <div className="custom-container">
          <div className="text-center">
            <img src="/logo.jpg" alt="" className="logo" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="form-main">
            <div className="form-group">
              <label htmlFor="emailid" className="form-label">
                Email ID
              </label>
              <input
                type="text"
                id="emailid"
                {...register("emailid", { required: true })}
                className={`form-control ${errors.emailid ? "is-invalid" : ""}`}
                placeholder="Enter Email ID"
                autoComplete="off"
              />
              {errors.emailid && (
                <div className="invalid-feedback">Email ID is required</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="sponserid" className="form-label">
                Sponsor Code
              </label>
              <input
                type="text"
                id="sponserid"
                {...register("sponserid", { required: true })}
                className={`form-control ${errors.sponserid ? "is-invalid" : ""}`}
                placeholder="Enter Sponsor"
                autoComplete="off"
              />
              {errors.sponserid && (
                <div className="invalid-feedback">Sponsor Code is required</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                {...register("password", { required: true })}
                className={`form-control ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="******"
                autoComplete="off"
              />
              {errors.password && (
                <div className="invalid-feedback">Password is required</div>
              )}
            </div>

            {/* <div className="remember-option mt-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  Remember Me
                </label>
              </div>
              <a href="#" className="forgot">
                Forgot Pin?
              </a>
            </div> */}

            <Button type="submit" className="cmn-btn w-100">
              Sign Up
            </Button>

            <p className="signup">
              Have an account? <Link to="/login">Sign in</Link>
            </p>
          </form>
        </div>
      </div>

      <div className="space-sticky"></div>
      <div className="border-sections-home"></div>
    </>
  );
}

export default Signuppage;
