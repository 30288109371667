import React, { useState, useEffect } from "react";
import Tablist from "../../component/Tablist";
import Invite from "../../images/invite-icon.png";
import Gift from "../../images/gift-stand.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Promotion = () => {
  const [profilememberid, setprofilememberid] = useState();
  const [linkprofilememberid, setlinkprofilememberid] = useState();
  //const [isCopied, setIsCopied] = useState(false);
  const { auth } = useAuth();

  useEffect(() => {
    return async () => {
      await profileaccount();
    };
  }, []);

  const profileaccount = async () => {
    var response = await ApiService.getprofile(auth);
    const { status, message, data } = response.data;
    if (status) {
      setprofilememberid(data.memberid);
      setlinkprofilememberid("https://cubigames.org/signup/" + data.memberid);
    }
  };

  const CopyReferID = () => {
    toast("Referral Code is Copied.", { type: "success", isLoading: false });
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition: Slide
      />
      {/* <Tablist></Tablist> */}
      <section className="refer">
        <div className="custom-container">
          <div className="row">
            <div className="col-12 text-center">
              <img src={Invite} alt="" className="invite-icon" />
              <h6 className="heading">
                Invite Your Friend to play WinStaar Games <br /> and Earn
                Rewards
              </h6>
            </div>
            <div className="col-12">
              <div className="ref-box">
                <span>Your Referral Code</span>
                <h3>{profilememberid}</h3>
                <CopyToClipboard
                  text={linkprofilememberid}
                  onCopy={CopyReferID}
                >
                  <div className="copy-area">
                    <Link className="share-btn">
                      Share Referral Code <FontAwesomeIcon icon={faCopy} />{" "}
                    </Link>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className="col-12 text-center">
              <img src={Gift} alt="" className="gift-stand" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Promotion;
