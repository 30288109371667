import React from "react";

function Helppage({titles}) {

  titles('Help');

  return (
    <section className="section-b-space">
      <div className="auth-form">
        <div className="custom-container">
          <div className="help-center">
            <h2 className="fw-semibold">What issues are you facing?</h2>
            <div
              className="accordion accordion-flush help-accordion"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                  >
                    What is a PWA Template
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    A PWA (Progressive Web App) template is a pre-designed
                    starting point or framework that helps developers build
                    Progressive Web Applications more efficiently. A PWA is a
                    type of web application that combines the features of a
                    website and a mobile app, providing a native-like experience
                    to users across different devices and platforms.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                  >
                    What is Cryptocurrency
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Cryptocurrency, often referred to as crypto, is a digital or
                    virtual form of currency that uses cryptography for security
                    and operates independently of a central bank.
                    Cryptocurrencies leverage blockchain technology, a
                    decentralized ledger system, to record transactions and
                    control the creation of new units.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How to make a payment through Crypto?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    To make a payment through cryptocurrency, you'll generally
                    need the following steps:
                    <ul>
                      <li>
                        Choose a Cryptocurrency: Decide which cryptocurrency you
                        want to use for the payment. Bitcoin (BTC) is the most
                        widely accepted, but other popular options include
                        Ethereum (ETH), Litecoin (LTC), and Ripple (XRP), among
                        others.
                      </li>

                      <li>
                        Choose a Wallet: Select a digital wallet that supports
                        the cryptocurrency you're using. Wallets can be
                        software-based (mobile or desktop apps) or hardware
                        devices specifically designed for cryptocurrency
                        storage.
                      </li>

                      <li>
                        Initiate the Transaction: Open your chosen wallet and
                        find the option to send or transfer funds. Enter the
                        recipient's wallet address and the payment amount.
                      </li>

                      <li>
                        Confirm Transaction Details: Some wallets may ask you to
                        review the transaction details and confirm once again.
                        This step ensures you're sending the correct amount to
                        the right recipient.
                      </li>

                      <li>
                        Pay Transaction Fees: Cryptocurrency transactions often
                        incur fees, which can vary depending on the network
                        congestion and the chosen cryptocurrency. The wallet
                        will typically provide an option to adjust the fee or
                        choose from preset options. Higher fees generally result
                        in faster confirmations.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                  >
                    How to utilize coin in cryptocurrency
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        Choose a Wallet: Select a digital wallet that supports
                        the specific coin you want to utilize.
                      </li>
                      <li>
                        Obtain the Coin: You need to acquire the coin through a
                        cryptocurrency exchange or a peer-to-peer transaction.
                      </li>
                      <li>
                        Store the Coin: Once you have obtained the coin,
                        transfer it to your chosen wallet.
                      </li>
                      <li>
                        Secure your Wallet: It's crucial to keep your wallet
                        secure to protect your coins.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                  >
                    How will the money be transferred to my wallet?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>
                        Bank Transfer: If your wallet is linked to your bank
                        account, you can typically transfer money from your bank
                        account directly to your wallet.
                      </li>
                      <li>
                        Credit/Debit Card: Many wallet services allow you to add
                        funds to your wallet using a credit or debit card.
                      </li>
                      <li>
                        Mobile Payment Apps: Some wallets are integrated with
                        popular mobile payment apps like Paytm, Google pay,
                        PayPal, Apple Pay.
                      </li>
                      <li>
                        Cryptocurrency Transfer: If your wallet supports
                        cryptocurrencies, you can receive funds by providing the
                        sender with your wallet address or QR code.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Helppage;
