import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";
const Deposithistory = ({ titles }) => {
  titles("Deposit History");

  const { auth } = useAuth();
  const [history, sethistory] = useState([]);

  const Deposithistory = async () => {
    const response = await ApiService.getdeposithistory({}, auth);
    const { status, message, data } = response.data;
    if (status) {
      sethistory(data);
    }
  };

  useEffect(() => {
    return async () => {
      await Deposithistory();
    };
  }, []);

  return (
    <div>
      <section className="wh-sec">
        <div className="custom-container">
          <div className="row mt-3">
            {history.length > 0 ? (
              history.map((item, index) => (
                <div className="col-12">
                  <div className="transact-details">
                    <div className="top">
                      <span className="heading">Deposit</span>
                      <span className="status">{item.status}</span>
                    </div>
                    <ul>
                      <li>
                        Balance<span>₹{item.balance}</span>
                      </li>
                      <li>
                        Type<span>{item.type}</span>
                      </li>
                      <li>
                        Time<span>{item.dated}</span>
                      </li>
                      <li>
                        Order Number
                        <span>
                          {item.orderid}
                          <FontAwesomeIcon icon={faCopy} />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12">
                <p>No Record Found</p>
              </div>
            )}

            {/* <div className="col-12">
                        <div className="transact-details">
                            <div className="top">
                                <span className="heading">Deposit</span>
                                <span className="status">Complete</span>
                            </div>
                            <ul>
                                <li>Balance<span>₹5000</span></li>
                                <li>Type<span>Bank Card</span></li>
                                <li>Time<span>2024-06-01 21:15:35</span></li>
                                <li>Order Number<span>WD2024060107093592606145d <FontAwesomeIcon icon={faCopy}/></span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="transact-details">
                            <div className="top">
                                <span className="heading">Deposit</span>
                                <span className="status red">Failed</span>
                            </div>
                            <ul>
                                <li>Balance<span>₹5000</span></li>
                                <li>Type<span>Bank Card</span></li>
                                <li>Time<span>2024-06-01 21:15:35</span></li>
                                <li>Order Number<span>WD2024060107093592606145d <FontAwesomeIcon icon={faCopy}/></span></li>
                            </ul>
                        </div>
                    </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Deposithistory;
