import React, { useEffect, useState } from "react";
import Icon1 from "../../images/deposit-icon.png";
import Icon2 from "../../images/withdraw-icon.png";
import Icon3 from "../../images/deposit-history-icon.png";
import Icon4 from "../../images/with-history-icon.png";
import Walleticon from "../../images/walleticon.png";
import { Link } from "react-router-dom";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";

function Walletpage() {
  const { auth } = useAuth();
  const [balance, setbalance] = useState(0);

  useEffect(() => {
    LoadBalance();
  }, []);

  const LoadBalance = async () => {
    var response = await ApiService.getbalance(auth);
    const { status, message, data } = response.data;
    if (status) {
      setbalance(data);
    }
  };

  return (
    <div>
      <section className="wallet-sec">
        <div className="custom-container">
          <div className="balance">
            <div className="text-center">
              <img src={Walleticon} alt="" />
              <h6>Total Balance</h6>
            </div>
            <div className="amt">₹{balance}</div>
          </div>
        </div>
      </section>
      <section className="option-sec">
        <div className="custom-container">
          <div className="option">
            <div className="row">
              <div className="col-3">
                <Link to="/deposit" className="option-box">
                  <img src={Icon1} alt="icon1" />
                  <h6>Deposit</h6>
                </Link>
              </div>
              <div className="col-3">
                <Link to="/withdraw" className="option-box">
                  <img src={Icon2} alt="icon1" />
                  <h6>Withdraw</h6>
                </Link>
              </div>
              <div className="col-3">
                <Link to="/deposithistory" className="option-box">
                  <img src={Icon3} alt="icon1" />
                  <h6>Deposit History</h6>
                </Link>
              </div>
              <div className="col-3">
                <Link to="/withdrawhistory" className="option-box">
                  <img src={Icon4} alt="icon1" />
                  <h6>Withdrawal History</h6>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Walletpage;
