import React from 'react'
import { Link } from "react-router-dom";

function Bottomnavbar({selectactive,setselectactive}) {
  return (
    <div className="navbar-menu">
    <div className="scanner-bg">
      <Link to='/promote' className="scanner-btn">
        <img className="img-fluid" src="svg/promotion.svg" alt="scan" />
      </Link>
    </div>
    <ul>
      <li className={selectactive === 1 ? "active" : ""}>
        <Link
          to="/home"
          onClick={() => {
            setselectactive(1);
          }}
        >
          <div className="icon">
            <img
              className={selectactive === 1 ? "unactive" : "active"}
              src="svg/home.svg"
              alt="mPay"
            />
            <img
              className={selectactive === 1 ? "active" : "unactive"}
              src="svg/home.svg"
              alt="mPay"
            />
          </div>
          <h5 className={selectactive === 1 ? "active" : ""}>Home</h5>
        </Link>
      </li>

      <li className={selectactive === 2 ? "active" : ""}>
        <Link
          to="/activity"
          onClick={() => {
            setselectactive(2);
          }}
        >
          <div className="icon">
            <img
              className={selectactive === 2 ? "unactive" : "active"}
              src="svg/achiever.svg"
              alt="categories"
            />
            <img
              className={selectactive === 2 ? "active" : "unactive"}
              src="svg/achiever.svg"
              alt="categories"
            />
          </div>
          <h5 className={selectactive ===2 ? "active" : ""}>Activity</h5>
        </Link>
      </li>

      <li>
        <Link to='/promote'>
        <div className="icon">
          <p></p>
          </div>
        <h5>Promotion</h5>
        </Link>
        </li>

      <li className={selectactive === 3 ? "active" : ""}>
        <Link
          to="/wallet"
          onClick={() => {
            setselectactive(3);
          }}
        >
          <div className="icon">
            <img
              className={selectactive === 3 ? "unactive" : "active"}
              src="svg/wallet.svg"
              alt="bag"
            />
            <img
              className={selectactive === 3 ? "active" : "unactive"}
              src="svg/wallet.svg"
              alt="bag"
            />
          </div>
          <h5 className={selectactive === 3 ? "active" : ""}>Wallet</h5>
        </Link>
      </li>

      <li className={selectactive === 4 ? "active" : ""}>
        <Link
          to="/profile"
          onClick={() => {
            setselectactive(4);
          }}
        >
          <div className="icon">
            <img
              className={selectactive === 4 ? "unactive" : "active"}
              src="svg/user.svg"
              alt="profile"
            />
            <img
              className={selectactive === 4 ? "active" : "unactive"}
              src="svg/user-fill.svg"
              alt="profile"
            />
          </div>
          <h5 className={selectactive === 4 ? "active" : ""}>Profile</h5>
        </Link>
      </li>
    </ul>
  </div>
  )
}

export default Bottomnavbar