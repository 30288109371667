import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

// Create an Axios instance
const apiClient = axios.create({
  baseURL: apiUrl, // Replace with your API base URL
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    //"Authorization":"Bearer "+auth
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Add any request modifications here, like adding auth tokens
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error("Server Error:", error.response);
    } else if (error.request) {
      // Request was made but no response received
      console.error("Network Error:", error.request);
    } else {
      // Something else caused the error
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

const ApiService = {
  logincallapi: async (request) => {
    var response = await apiClient.post("api/Account/Login", request);
    return response;
  },
  validatelogintoken: async (request) => {
    var response = await apiClient.post("api/Account/ValidateLogin", request);
    return response;
  },
  registercallapi: async (request) => {
    var response = await apiClient.post("api/Account/Register", request);
    return response;
  },


  getprofile: async (tokens) => {
    var response = await apiClient.get(`api/Profile/get-profile`, {
      headers: { Authorization: "Bearer " + tokens },
    });
    return response;
  },getbalance: async (tokens) => {
    var response = await apiClient.get(`api/Profile/get-balance`, {
      headers: { Authorization: "Bearer " + tokens },
    });
    return response;
  },

  //-----------Game Api-----------------/
  GameBalanceApi: async (tokens) => {
    var response = await apiClient.get(`api/GameCasino/game-balance`, {
      headers: { Authorization: "Bearer " + tokens },
    });
    return response;
  },
  Gametimerapi: async (interval, tokens) => {
    var response = await apiClient.get(`api/GameCasino/time-left/${interval}`, {
      headers: { Authorization: "Bearer " + tokens },
    });
    return response;
  },
  Gamedetails: async (interval, tokens) => {
    var response = await apiClient.get(`api/GameCasino/last-game/${interval}`, {
      headers: { Authorization: "Bearer " + tokens },
    });
    return response;
  },
  LastGameresult: async (interval, tokens) => {
    var response = await apiClient.get(
      `api/GameCasino/last-gameresult/${interval}`,
      {
        headers: { Authorization: "Bearer " + tokens },
      }
    );
    return response;
  },
  GamePlacebetting: async (request, tokens) => {
    var response = await apiClient.post(`api/GameCasino/place-bets`, request, {
      headers: { Authorization: "Bearer " + tokens },
    });
    return response;
  },
  CurrentGamelist: async (interval, page, tokens) => {
    var response = await apiClient.get(
      `api/GameCasino/game-history/${interval}/${page}`,
      {
        headers: { Authorization: "Bearer " + tokens },
      }
    );
    return response;
  },
  BettingGamelist: async (interval, page, tokens) => {
    var response = await apiClient.get(
      `api/GameCasino/bets-history/${interval}/${page}`,
      {
        headers: { Authorization: "Bearer " + tokens },
      }
    );
    return response;
  },
  CurrentBetslist: async (interval, tokens) => {
    var response = await apiClient.get(
      `api/GameCasino/current-bets/${interval}`,
      {
        headers: { Authorization: "Bearer " + tokens },
      }
    );
    return response;
  },
  //-----------Game Api End-----------------/
};

export default ApiService;
