import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import tether from "../../images/tether.png";
import upi from "../../images/upi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare";
import { useForm } from "react-hook-form";
import ApiService from "../../Api/ApiService";
import useAuth from "../../context/useAuth";
import { ToastContainer, toast } from "react-toastify";

const Withdrawal = ({ titles }) => {
  titles("Withdrawal");
  const { auth } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { amount: null, paymethod: 1 } });
  const navigate = useNavigate();

  const [balance, setbalance] = useState(0);

  const handlepaymentmethod = async (values) => {
    setValue("paymethod", values);
  };

  const Submitwithdrawal = async (data) => {
    //console.log({ data });
    await Placewithdrawal(data.paymethod, data.amount);
  };

  const Placewithdrawal = async (type, amount) => {
    const response = await ApiService.withdrawalplace(
      { type: type, amount: amount },
      auth
    );
    //console.log(response);
    const { status, message, data } = response.data;
    if (status) {
      toast(message, { type: "success", isLoading: false });
      navigate("/withdrawhistory");
    } else {
      toast(message, { type: "error", isLoading: false });
    }
  };

  const updatebalance = async (wallet) => {
    const response = await ApiService.getbalance(auth);
    const { status, message, data } = response.data;
    if (status) {
      setbalance(data);
    }
  };

  useEffect(() => {
    updatebalance(5);
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition: Slide
      />
      <section>
        <div className="custom-container">
          <div className="statistics-banner">
            <div className="d-flex justify-content-center gap-3">
              <div className="statistics-content">
                <h6>Balance</h6>
                <h5 className="text-white fw-semibold">₹{balance}</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="custom-container">
          <form onSubmit={handleSubmit(Submitwithdrawal)}>
            <div className="row">
              <div className="col-6 text-center">
                <Link
                  onClick={() => {
                    handlepaymentmethod(1);
                  }}
                  className={
                    getValues("paymethod") === 1 ? "pmt-box active" : "pmt-box"
                  }
                >
                  <img src={tether} alt="icon" />
                  <h6>USDT(BEP20)</h6>
                </Link>
              </div>

              <div className="col-6 text-center">
                <Link
                  onClick={() => {
                    handlepaymentmethod(2);
                  }}
                  className={
                    getValues("paymethod") === 2 ? "pmt-box active" : "pmt-box"
                  }
                >
                  <img src={upi} alt="icon" />
                  <h6>INR (₹)</h6>
                </Link>
              </div>
            </div>

            <div className="title">
              <h6 className="white-text">
                <FontAwesomeIcon icon={faCreditCardAlt} className="iconsize" />{" "}
                Withdrawal Method: $
              </h6>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="input-group mb-3">
                  <input
                    type="number"
                    {...register("amount", { required: true })}
                    className={`form-control ${
                      errors.amount ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Withdraw Amount"
                    autoComplete="off"
                  />
                  {errors.amount && (
                    <div className="invalid-feedback">
                      Withdrawal Amount is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-3">
                <button type="submit" className="pmt-btn mb-2">
                  Withdraw Money
                </button>
              </div>
              <div className="col-12 mt-3 mb-3 text-center">
                <Link to="/address" className="address-btn">
                  <FontAwesomeIcon icon={faPlusSquare} className="iconsize" />{" "}
                  Add USDT(BEP20) Address
                </Link>
              </div>

              {/* <div className="col-12 mt-3 mb-3 text-center">
                <Link to="/bankdetails" className="address-btn">
                  <FontAwesomeIcon icon={faPlusSquare} className="iconsize" />{" "}
                  Add Bank Account Details
                </Link>
              </div> */}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Withdrawal;
